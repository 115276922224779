<!-- Spinner -->
<ng-template #spinner>
  <div class="d-flex justify-content-center m-2">
    <div class="spinner-border"></div>
  </div>
</ng-template>

<!-- Gird Entry -->
<ng-template #block_entry_template let-slide="slide" let-page="page" let-tree="tree">
  <ng-container *ngIf="slide && page && tree">
    <figure [routerLink]="['/' + tree.path]"
            class="mdsf-thumbnail">
      <ng-container *ngIf="page.hidden === false && slide.hidden === false">
        <div class="resource-type-icon" [title]="mediaControlService.getMediaTypeLabel({ type: page.mediaType }) | translate">
          <span [class]="'fa ' + mediaControlService.getMediaTypeIcon({ type: page.mediaType })"></span>
        </div>
        <img *ngIf="(appSharedFacade.media$(page.thumbnailMedia) | async) as media"
             [src]="mediaControlService.getUrl(media, 'thumbnail')"
             [alt]="media.altText"/>
        <div class="img-placeholder d-flex justify-content-center align-items-center" *ngIf="!page.thumbnailMedia">
          <span [class]="'fa text-secondary fa-3x ' + mediaControlService.getMediaTypeIcon({ type: page.mediaType })"></span>
        </div>
        <figcaption>
          <div class="text">
              <span class="truncate truncate-multiline">
                <!--
                <span *ngIf="slide?.page?.compoundNumber">{{ slide.page.compoundNumber }}:</span>
                -->
                {{ page.title }}
              </span>
            </div>
            <a class="nav" [routerLink]="['/' + tree.path]">
              <i class="fa fa-chevron-right" aria-label=""></i>
            </a>
          </figcaption>
          <!--
          <figcaption class="temporary" *ngIf="!slides.loaded">
            <div class="box box-upper"></div>
            <div class="box box-lower"></div>
          </figcaption>
          -->
      </ng-container>
    </figure>
  </ng-container>
</ng-template>

<!-- Content -->
<div class="mdsf-grid p-2 thumbnail-container scrollable" *ngIf="(nodeSharedFacade.currentSelectedSlideNodesFiltered$ | async) | sortBy_shared:'asc':'position' as slides">
  <ng-container *ngFor="let slide of slides">
    <ng-container *ngIf="slides !== undefined; else spinner"></ng-container>
    <ng-container *ngTemplateOutlet="block_entry_template;context:{
                  slide: slide,
                  page: (appSharedFacade.page$(slide.page) | async),
                  tree: (appSharedFacade.tree$(slide.id) | async)}">
    </ng-container>
  </ng-container>
</div>
