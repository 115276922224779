import { enableProdMode, NgModuleRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Store } from '@ngxs/store';
import * as Sentry from "@sentry/angular-ivy";
import { AuthControlState } from '@medsurf/flat-states';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// Sentry Init
Sentry.init({
  dsn: environment.config.sentry.dsn,
  integrations: [
    new Sentry.Integrations.Breadcrumbs({
      console: environment.config.sentry.environment !== 'development',
    }),
  ],
  environment: environment.config.sentry.environment,
  autoSessionTracking: false,
  tracesSampleRate: 1,
  release: environment.config.version
});

// Angular
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then((ref: NgModuleRef<AppModule>) => {
    const store = ref.injector.get(Store);
    store.select(AuthControlState.user$).subscribe((user) => {
      if (user) {
        Sentry.setUser({
          id: user.id.toString(),
          username: `${user.firstName} ${user.lastName}`,
        });
        Sentry.setContext('user', user);
      }
    });
  })
  .catch(err => console.error(err));
