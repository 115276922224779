<div class="mdsf-slide h-100"
     *ngIf="media">
  <div class="slide-image-container slide-video-container h-100 w-100 p-3">
    <ng-container *ngIf="!errorState">
      <video [src]="mediaControlService.getUrl(media)"
             id="video-{{ media.id }}"
             [poster]="mediaControlService.getUrl(media, 'poster')"
             (click)="localToggle()"
             (timeupdate)="updateProgress()"
             preload="auto"
             #videoElement>
      </video>
      <div class="video-controls-container">
        <div class="button"
             (click)="localToggle()">
          <span class="fa fa-fw fa-play"
                *ngIf="!isPlaying"></span>
          <span class="fa fa-fw fa-pause"
                *ngIf="isPlaying"></span>
        </div>
        <div class="button grow">
          <div class="mdsf-progress"
                (mousedown)="localSeekBarMousedown($event)">
            <div class="mdsf-progress-wrap"
                  #progressWrap>
              <div class="mdsf-progress-bar"
                  [style.transform]="'scaleX(' + progress + ')'">
              </div>
            </div>
          </div>
        </div>
        <div class="button" ngbDropdown placement="top-right">
          <div id="playback-speed-dropdown" ngbDropdownToggle [ngbTooltip]="('playback_speed' | translate)" placement="top-right">
            <span>{{ speed }}x </span>
            <span class="fa fa-gauge"></span>
          </div>
          <div ngbDropdownMenu aria-labelledby="playback-speed-dropdown">
            <button ngbDropdownItem 
              *ngFor="let sp of playbackSpeed"
              (click)="adjustPlaybackSpeed(sp)"
              [class.active]="sp === speed"
              >
              {{ sp }}x
            </button>
          </div>
        </div>
        <div class="button" [class.active]="loop" [ngbTooltip]="('endless_loop' | translate)" placement="top-right" (click)="toggleEndlessLoop()">
          <span class="fa fa-rotate"></span>
        </div>
      </div>
    </ng-container>
  </div>
</div>
