import { version } from './version';
import { config } from './config';

export const environment = {
  production: true,
  config: {
    s3endpointUrl: config?.s3endpointUrl || 'https://s3-zh.os.switch.ch/',
    s3Bucket: config?.s3Bucket || 'medsurf',
    endpointUrl: config?.endpointUrl || 'http://localhost:3000/',
    authorUrl: config?.authorUrl || 'http://localhost:4200/',
    version: version.version,
    revision: version.revision,
    branch: version.branch,
    sentry: {
      dsn: config?.sentryDsn || 'https://905a179965d5444ab68036089735fbd7@o1140535.ingest.sentry.io/4505165835993088',
      environment: config?.sentryEnvironment || 'development',
    }
  }
};
