import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import * as FlatModels from '@medsurf/flat-models';
import { MenuViewerFacade, NavigationControlFacade, SlideViewerFacade } from '@medsurf/flat-facades';

@Component({
  selector: 'medsurf-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent {
  /**
   * Inputs
   */
  @Input() public text: string;
  @Input() public isSlideDescription: boolean;

  /**
   * View Children
   */
  @ViewChild('textElement', {static: false}) public textElement: ElementRef;

  /**
   * Members
   */
  private uuidRegExp = '[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}';

  /**
   * Constructor
   *
   * @param navigationControlFacade: NavigationControlFacade
   * @param slideViewerFacade: SlideViewerFacade
   * @param menuViewerFacade: MenuViewerFacade
   */
  constructor(public navigationControlFacade: NavigationControlFacade,
              public slideViewerFacade: SlideViewerFacade,
              public menuViewerFacade: MenuViewerFacade) {
  }

  /**
   * Format Description
   *
   * @param text: string
   * @param groups: FlatModels.GroupEntityModels.Group[]
   * @param themeColor: string
   */
  public formatDescription(text: string,
                           groups: FlatModels.GroupEntityModels.Group[],
                           themeColor: string) {
    let content = '';
    if (text) {
      content += text;
      this.addLinkOnclickHandler(groups, themeColor);
    }
    return content;
  }

  /**
   * Add Link On Click Handler
   *
   * @param groups: FlatModels.GroupEntityModels.Group[]
   * @param themeColor: string
   */
  public addLinkOnclickHandler(groups: FlatModels.GroupEntityModels.Group[],
                               themeColor: string) {
    if (this.textElement) {
      const links = Array.from(this.textElement.nativeElement.getElementsByTagName('a'));
      /* TODO Old
      links.filter((link: HTMLAnchorElement) => link.className === 'internal' || link.className === 'external')
        .forEach((link: HTMLAnchorElement) => {
          const isInternal = link.className === 'internal';
          link.className += ` ${themeColor}`;
          link.href = isInternal ?
            ['/folien', link.pathname.replace(/(\/slides\/|\/folien\/)/, '')].join('/') :
            link.href;
          link.target = link.target ?? '_blank';

        });
      */
      links.filter((link: HTMLAnchorElement) => link.className === 'internal' || link.className === 'external')
        .forEach((link: HTMLAnchorElement) => {
          link.className += ` ${themeColor}`;
          link.target = link.target ?? '_blank';
        });
      links.filter((link: HTMLAnchorElement) => link.className === '')
        .forEach((link: HTMLAnchorElement) => {
          link.target = link.target ?? '_blank';
          link.className += ` ${themeColor}`;
        });
      links.filter((link: HTMLAnchorElement) => link.className === 'marker')
        .forEach((link: HTMLAnchorElement) => {
          let updateColor = false;
          let href: string = link.href.replace(/^(http|https):\/\/([a-zA-Z0-9:.]*\/)+/, '');
          if (!href) {
            updateColor = true;
            href = link.id;
            if (!href) {
              return;
            }
          }
          // Layer
          let layerId = null;
          if (href.includes('layer')) {
            const layerRegex = new RegExp('layer-([0-9]+)', 'g');
            const layerResult = layerRegex.exec(href);
            if (layerResult && layerResult.length === 2) {
              layerId = layerResult[1];
            }
          }
          // Sequence
          let sequenceId = null;
          if (href.includes('sequence')) {
            const sequenceRegex = new RegExp('sequence-([0-9]+)', 'g');
            const sequenceResult = sequenceRegex.exec(href);
            if (sequenceResult && sequenceResult.length === 2) {
              sequenceId = sequenceResult[1];
            }
          }
          // Marker
          const markerRegex = new RegExp(`(group|annotation)-(${this.uuidRegExp})`);
          const markerResult = markerRegex.exec(href);
          if (markerResult && markerResult.length === 3) {
            href = (markerResult[0].includes('group') || markerResult[1].includes('annotation')) ?
              markerResult[0] :
              `annotation-${markerResult[2]}`;
          }

          const [type, ...idParts] = href.split('-');
          const id = idParts.join('-');

          if (link.innerText.trim() === '<') {
            link.innerHTML = '<i class="fa fa-location-dot"></i>';
          }

          if (type && id) {
            const group = groups.find(g => g.id === id);
            link.style.color = group && group.color ? group.color : themeColor;
            if (!updateColor) {
              link.id = href;
              link.type = 'group';
              link.removeAttribute('href');
              link.onmouseleave = () => {
                this.slideViewerFacade.requestSetGroupId(null);
                link.style.cursor = 'default';
                link.style.fontWeight = 'normal';
              };
              link.onmouseenter = () => {
                link.style.fontWeight = 'bold';
                link.style.cursor = 'pointer';
                if (layerId) {
                  this.slideViewerFacade.requestSetLayerIndex(Number.parseInt(layerId, 10));
                }
                if (sequenceId) {
                  this.slideViewerFacade.requestSetImageIndex(Number.parseInt(sequenceId, 10));
                }
                this.slideViewerFacade.requestSetGroupId(id);
              };
              link.onclick = (event) => {
                event.stopImmediatePropagation();
              };
            }
          }
        });
    }
  }
}
