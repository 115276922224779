import { shuffle } from 'lodash';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Navigate, RouterState } from '@ngxs/router-plugin';
import * as FlatModels from '@medsurf/flat-models';
import * as FlatActions from '@medsurf/flat-actions';
import { MatomoControlService, MessageControlService } from '@medsurf/flat-services';
import * as FlatStates from '../../internal';

/**
 * Training Viewer Token
 */
export const TRAINING_VIEWER_TOKEN = new StateToken<TrainingViewerStateModel>('trainingViewer');

/**
 * Training Viewer State Model
 */
export interface TrainingViewerStateModel {
  // Loaded Slides
  availableBlocks: { [id: string]: FlatModels.TrainingViewerModels.AvailableBlock[] };
  availableSlides: { [id: string]: FlatModels.TrainingViewerModels.AvailableSlide[] };
  // Current Slides
  selectedStartUrl: string | null,
  selectedSlides: string[];
  selectedSlideResults: { [id: string]: FlatModels.TrainingViewerModels.TrainingResultType[] };
}

/**
 * TODO: error when we start a case and then switch to a quiz -> we get too many quiz entries then: fix this
 * Training Viewer State
 */
@State<TrainingViewerStateModel>({
  name: TRAINING_VIEWER_TOKEN,
  defaults: {
    // Loaded Trainings
    availableBlocks: {},
    availableSlides: {},
    // Current Training
    selectedStartUrl: null,
    selectedSlides: [],
    selectedSlideResults: {},
  }
})
@Injectable()
export class TrainingViewerState {
  //<editor-fold desc="Selectors">

  /**
   * Selector typedAvailableBlocks$
   *
   * @param state: TrainingViewerStateModel
   */
  @Selector([TrainingViewerState])
  public static typedAvailableBlocks$(state: TrainingViewerStateModel): { [id: string]: FlatModels.TrainingViewerModels.AvailableBlock[] } {
    return state.availableBlocks;
  }

  /**
   * Selector currentAvailableBlocks$
   *
   * @param typedAvailableBlocks: { [id: string]: FlatModels.TrainingViewerModels.AvailableBlock[] }
   * @param subject: FlatModels.NodeEntityModels.Node
   */
  @Selector([
    TrainingViewerState.typedAvailableBlocks$,
    FlatStates.NodeEntityState.currentSelectedSubjectNode$
  ])
  public static currentAvailableBlocks$(typedAvailableBlocks: { [id: string]: FlatModels.TrainingViewerModels.AvailableBlock[] },
                                        subject: FlatModels.NodeEntityModels.Node) {
    return subject && typedAvailableBlocks && typedAvailableBlocks[subject.id] !== undefined ? typedAvailableBlocks[subject.id] : null;
  }

  /**
   * Lazy Selector currentAvailableBlocksByParentId$
   *
   * @param currentAvailableBlocks: FlatModels.TrainingViewerModels.AvailableBlock[]
   */
  @Selector([TrainingViewerState.currentAvailableBlocks$])
  public static currentAvailableBlocksByParentId$(currentAvailableBlocks: FlatModels.TrainingViewerModels.AvailableBlock[]) {
    return (parentId: string) => {
      return currentAvailableBlocks ? currentAvailableBlocks.filter(e => e.parentId === parentId) : [];
    }
  }

  /**
   * Selector typedAvailableSlides$
   *
   * @param state: TrainingViewerStateModel
   */
  @Selector([TrainingViewerState])
  public static typedAvailableSlides$(state: TrainingViewerStateModel): { [id: string]: FlatModels.TrainingViewerModels.AvailableSlide[] } {
    return state.availableSlides;
  }

  /**
   * Selector currentAvailableSlides$
   *
   * @param typedAvailableSlides: { [id: string]: FlatModels.TrainingViewerModels.AvailableSlide[] }
   * @param subject: FlatModels.NodeEntityModels.Node
   */
  @Selector([
    TrainingViewerState.typedAvailableSlides$,
    FlatStates.NodeEntityState.currentSelectedSubjectNode$
  ])
  public static currentAvailableSlides$(typedAvailableSlides: { [id: string]: FlatModels.TrainingViewerModels.AvailableSlide[] },
                                        subject: FlatModels.NodeEntityModels.Node) {
    return subject && typedAvailableSlides && typedAvailableSlides[subject.id] !== undefined ? typedAvailableSlides[subject.id] : null;
  }

  /**
   * Lazy Selector currentAvailableBlocksByParentId$
   *
   * @param currentAvailableSlides: FlatModels.TrainingViewerModels.AvailableSlide[]
   */
  @Selector([TrainingViewerState.currentAvailableSlides$])
  public static currentAvailableSlidesByParentId$(currentAvailableSlides: FlatModels.TrainingViewerModels.AvailableSlide[]) {
    return (parentId: string) => {
      return currentAvailableSlides ? currentAvailableSlides.filter(e => e.parentId === parentId) : [];
    }
  }

  /**
   * Lazy Selector currentAvailableBlocksByParentId$
   *
   * @param currentAvailableBlocks: FlatModels.TrainingViewerModels.AvailableBlock[]
   * @param currentAvailableSlides: FlatModels.TrainingViewerModels.AvailableSlide[]
   */
  @Selector([
    TrainingViewerState.currentAvailableBlocks$,
    TrainingViewerState.currentAvailableSlides$
  ])
  public static currentAvailableSlidesByChapterId$(currentAvailableBlocks: FlatModels.TrainingViewerModels.AvailableBlock[],
                                                   currentAvailableSlides: FlatModels.TrainingViewerModels.AvailableSlide[]) {

    return (chapterId: string) => {
      return currentAvailableBlocks && currentAvailableSlides && currentAvailableBlocks.filter(b => b.parentId === chapterId).map(b => currentAvailableSlides.filter(s => s.parentId === b.id)).reduce((acc, val) => acc.concat(val), []) || [];
      }
  }

  /**
   * Selector selectedStartUrl$
   *
   * @param state: TrainingViewerStateModel
   */
  @Selector([TrainingViewerState])
  public static selectedStartUrl$(state: TrainingViewerStateModel) {
    return state.selectedStartUrl;
  }

  /**
   * Selector selectedSlides$
   *
   * @param state: TrainingViewerStateModel
   */
  @Selector([TrainingViewerState])
  public static selectedSlides$(state: TrainingViewerStateModel) {
    return state.selectedSlides;
  }

  /**
   * Selector selectedSlideNodes$
   *
   * @param selectedSlides: string[]
   * @param entities: FlatModels.NodeEntityModels.NodeEntityType[]
   */
  @Selector([
    TrainingViewerState.selectedSlides$,
    FlatStates.NodeEntityState.entities$
  ])
  public static selectedSlideNodes$(selectedSlides: string[],
                                    entities: FlatModels.NodeEntityModels.NodeEntityType[]) {
    return Array.isArray(selectedSlides) && Array.isArray(entities) && entities.filter(e => selectedSlides.includes(e.id)) || [];
  }

  /**
   * Selector selectedSlidePages$
   *
   * @param selectedSlideNodes: FlatModels.NodeEntityModels.NodeEntityType[]
   * @param entities: FlatModels.PageEntityModels.PageEntityType[]
   */
  @Selector([
    TrainingViewerState.selectedSlideNodes$,
    FlatStates.PageEntityState.entities$
  ])
  public static selectedSlidePages$(selectedSlideNodes: FlatModels.NodeEntityModels.NodeEntityType[],
                                    entities: FlatModels.PageEntityModels.PageEntityType[]): FlatModels.PageEntityModels.Training[] {
    return Array.isArray(selectedSlideNodes) && Array.isArray(entities) && entities.filter(e => selectedSlideNodes.map(tn => tn.page as unknown as string).includes(e.id)) || [];
  }

  /**
   * Selector selectedSlideIndex$
   *
   * @param selectedSlides: string[]
   * @param currentSelectedSlideNode: FlatModels.NodeEntityModels.SlideNode
   */
  @Selector([
    TrainingViewerState.selectedSlides$,
    FlatStates.NodeEntityState.currentSelectedSlideNode$
  ])
  public static selectedSlideIndex$(selectedSlides: string[],
                                    currentSelectedSlideNode: FlatModels.NodeEntityModels.SlideNode) {
    return currentSelectedSlideNode && Array.isArray(selectedSlides) ? selectedSlides.findIndex(ss => ss === currentSelectedSlideNode.id) : null;
  }

  /**
   * Lazy Selector selectedQuestionIndex$
   * 
   * @param typedSelectedSlideResults: { [id: string]: FlatModels.TrainingViewerModels.TrainingResultType[] }
   * @param selectedSlides: string[]
   * @param selectedSlideNodes: FlatModels.NodeEntityModels.NodeEntityType
   */
  @Selector([
    TrainingViewerState.typedSelectedSlideResults$, 
    TrainingViewerState.selectedSlides$,
    TrainingViewerState.selectedSlideNodes$
  ])
  public static selectedQuestionIndex$(typedSelectedSlideResults: { [id: string]: FlatModels.TrainingViewerModels.TrainingResultType[] },
                                      selectedSlides: string[],
                                      selectedSlideNodes: FlatModels.NodeEntityModels.NodeEntityType[]) {
    let index = 0;
    const questionMap: { [key: string]: number } = {};

    selectedSlides.forEach(id => {
      const node = selectedSlideNodes.find(n => n.id === id);
      if (!node) return;
      const trainingResults = [...(typedSelectedSlideResults[node.page as unknown as string] || [])];
      trainingResults.sort((a, b) => a.order - b.order);
      trainingResults.forEach((trainingResult) => {
        questionMap[trainingResult.questionId] = index;
        index++;
      })
    })
    
    return (questionId: string) => {
      return Object.hasOwn(questionMap, questionId) ? questionMap[questionId] : null;
    }; 
  }

  /**
   * Selector selectedSlidePercentage$
   *
   * @param selectedSlideIndex: number | null
   * @param selectedSlides: string[]
   */
  @Selector([
    TrainingViewerState.selectedSlideIndex$,
    TrainingViewerState.selectedSlides$
  ])
  public static selectedSlidePercentage$(selectedSlideIndex: number | null,
                                         selectedSlides: string[]) {
    if (selectedSlideIndex !== null && Array.isArray(selectedSlides)) {
      const item = 1 / selectedSlides.length;
      return (item * (selectedSlideIndex + 1)) - item / 2;
    }
    return null;
  }

  /**
   * Selector previousSlideIndex$
   *
   * @param selectedSlideIndex: number | null
   */
  @Selector([TrainingViewerState.selectedSlideIndex$])
  public static previousSlideIndex$(selectedSlideIndex: number | null) {
    return selectedSlideIndex !== null && selectedSlideIndex > 0 ? (selectedSlideIndex - 1) : null;
  }

  /**
   * Selector previousSlideNode$
   *
   * @param previousSlideIndex: number | null
   * @param selectedSlides: string[]
   * @param entities: FlatModels.NodeEntityModels.NodeEntityType[]
   */
  @Selector([
    TrainingViewerState.previousSlideIndex$,
    TrainingViewerState.selectedSlides$,
    FlatStates.NodeEntityState.entities$
  ])
  public static previousSlideNode$(previousSlideIndex: number | null,
                                   selectedSlides: string[],
                                   entities: FlatModels.NodeEntityModels.NodeEntityType[]) {
    if (previousSlideIndex !== null && Array.isArray(selectedSlides) && Array.isArray(entities)) {
      const selectedSlide = selectedSlides[previousSlideIndex];
      if (selectedSlide) {
        return entities.find(e => e.id === selectedSlide);
      }
    }
    return null;
  }

  /**
   * Selector previousSlidePage$
   *
   * @param previousSlideNode: FlatModels.NodeEntityModels.NodeEntityType
   * @param entities: FlatModels.PageEntityModels.PageEntityType[]
   */
  @Selector([
    TrainingViewerState.previousSlidePage$,
    FlatStates.PageEntityState.entities$
  ])
  public static previousSlidePage$(previousSlideNode: FlatModels.NodeEntityModels.NodeEntityType,
                                   entities: FlatModels.PageEntityModels.PageEntityType[]) {
    return previousSlideNode && Array.isArray(entities) && entities.find(e => e.id === previousSlideNode.page as unknown as string) || null;
  }

  /**
   * Selector previousSlideUrl$
   *
   * @param previousSlideNode: FlatModels.NodeEntityModels.SlideNode | null
   * @param selectedQuizUrl: string | null
   * @param entities: FlatModels.TreeControlModels.TreeElement[]
   */
  @Selector([
    TrainingViewerState.previousSlideNode$,
    TrainingViewerState.selectedStartUrl$,
    FlatStates.TreeControlState.entities$
  ])
  public static previousSlideUrl$(previousSlideNode: FlatModels.NodeEntityModels.SlideNode | null,
                                  selectedQuizUrl: string | null,
                                  entities: FlatModels.TreeControlModels.TreeElement[]) {
    if (previousSlideNode) {
      const treeElement = entities.find(te => te.id === previousSlideNode.parentId);
      if (treeElement) {
        return 'quiz/' + treeElement.path + '/' + previousSlideNode.route;
      } else {
        throw Error("current_parent_tree_not_defined");
      }
    } else if (selectedQuizUrl) {
      return selectedQuizUrl;
    }
    return null;
  }

  /**
   * Selector nextSlideIndex$
   *
   * @param selectedSlideIndex: number | null
   * @param selectedSlides: string[]
   */
  @Selector([
    TrainingViewerState.selectedSlideIndex$,
    TrainingViewerState.selectedSlides$
  ])
  public static nextSlideIndex$(selectedSlideIndex: number | null,
                                selectedSlides: string[]) {
    return selectedSlideIndex !== null && (selectedSlideIndex + 1) < selectedSlides.length ? (selectedSlideIndex + 1) : null;
  }

  /**
   * Selector nextSlideNode$
   *
   * @param nextSlideIndex: number | null
   * @param selectedSlides: string[]
   * @param entities: FlatModels.NodeEntityModels.NodeEntityType[]
   */
  @Selector([
    TrainingViewerState.nextSlideIndex$,
    TrainingViewerState.selectedSlides$,
    FlatStates.NodeEntityState.entities$
  ])
  public static nextSlideNode$(nextSlideIndex: number | null,
                               selectedSlides: string[],
                               entities: FlatModels.NodeEntityModels.NodeEntityType[]) {
    if (nextSlideIndex !== null && Array.isArray(selectedSlides) && Array.isArray(entities)) {
      const selectedSlide = selectedSlides[nextSlideIndex];
      if (selectedSlide) {
        return entities.find(e => e.id === selectedSlide);
      }
    }
    return null;
  }

  /**
   * Selector nextSlidePage$
   *
   * @param nextSlideNode: FlatModels.NodeEntityModels.NodeEntityType
   * @param entities: FlatModels.PageEntityModels.PageEntityType[]
   */
  @Selector([
    TrainingViewerState.nextSlideNode$,
    FlatStates.PageEntityState.entities$
  ])
  public static nextSlidePage$(nextSlideNode: FlatModels.NodeEntityModels.NodeEntityType,
                               entities: FlatModels.PageEntityModels.PageEntityType[]) {
    return nextSlideNode && Array.isArray(entities) && entities.find(e => e.id === nextSlideNode.page as unknown as string) || null;
  }

  /**
   * Selector nextSlideUrl$
   *
   * @param nextSlideNode: FlatModels.NodeEntityModels.SlideNode | null
   * @param selectedQuizUrl: string | null
   * @param entities: FlatModels.TreeControlModels.TreeElement[]
   * @param blockPage: FlatModels.PageEntityModels.PageEntityType
   * @param chapterUrl: string
   */
  @Selector([
    TrainingViewerState.nextSlideNode$,
    TrainingViewerState.selectedStartUrl$,
    FlatStates.TreeControlState.entities$,
    FlatStates.PageEntityState.currentSelectedBlockPage$,
    FlatStates.NavigationControlState.chapterUrl$,
  ])
  public static nextSlideUrl$(nextSlideNode: FlatModels.NodeEntityModels.SlideNode | null,
                              selectedQuizUrl: string | null,
                              entities: FlatModels.TreeControlModels.TreeElement[],
                              blockPage: FlatModels.PageEntityModels.PageEntityType,
                              chapterUrl: string) {
    if (nextSlideNode) {
      const treeElement = entities.find(te => te.id === nextSlideNode.parentId);
      if (treeElement) {
        return 'quiz/' + treeElement.path + '/' + nextSlideNode.route;
      } else {
        throw Error("current_parent_tree_not_defined");
      }
    } else if (selectedQuizUrl) {
      if (blockPage.type === FlatModels.PageEntityModels.PageType.CASE) {
        return chapterUrl;
      }
      return selectedQuizUrl + '/results';
    }
    return null;
  }

  /**
   * Selector typedSelectedSlideResults$
   *
   * @param state: TrainingViewerStateModel
   */
  @Selector([TrainingViewerState])
  public static typedSelectedSlideResults$(state: TrainingViewerStateModel) {
    return state.selectedSlideResults;
  }

  /**
   * Selector selectedSlideResults$
   *
   * @param typedSelectedSlideResults: { [id: string]: FlatModels.TrainingViewerModels.TrainingResultType[] }
   */
  @Selector([TrainingViewerState.typedSelectedSlideResults$])
  public static selectedSlideResults$(typedSelectedSlideResults: { [id: string]: FlatModels.TrainingViewerModels.TrainingResultType[] }) {
    return (Object.entries(typedSelectedSlideResults)).map(([, entity]) => entity).reduce((acc, val) => acc.concat(val), []);
  }

  /**
   * Selector selectedTotalSlideResults$
   *
   * @param selectedSlideResults: FlatModels.TrainingViewerModels.TrainingResultType[]
   */
  @Selector([TrainingViewerState.selectedSlideResults$])
  public static selectedTotalSlideResults$(selectedSlideResults: FlatModels.TrainingViewerModels.TrainingResultType[]) {
    if (Array.isArray(selectedSlideResults)) {
      return selectedSlideResults.map(r => r.total).reduce((sum, a) => (sum || 0) + (a || 0), 0);
    }
    return 0;
  }

  /**
   * Selector selectedEmptySlideResults$
   *
   * @param selectedSlideResults: FlatModels.TrainingViewerModels.TrainingResultType[]
   */
  @Selector([TrainingViewerState.selectedSlideResults$])
  public static selectedEmptySlideResults$(selectedSlideResults: FlatModels.TrainingViewerModels.TrainingResultType[]) {
    if (Array.isArray(selectedSlideResults)) {
      return selectedSlideResults.map(r => r.empty).reduce((sum, a) => (sum || 0) + (a || 0), 0);
    }
    return 0;
  }

  /**
   * Selector selectedEmptyPercentageSlideResults$
   *
   * @param selectedTotalSlideResults: number
   * @param selectedEmptySlideResults: number
   */
  @Selector([
    TrainingViewerState.selectedTotalSlideResults$,
    TrainingViewerState.selectedEmptySlideResults$
  ])
  public static selectedEmptyPercentageSlideResults$(selectedTotalSlideResults: number,
                                                     selectedEmptySlideResults: number) {
    return Math.round(selectedEmptySlideResults / selectedTotalSlideResults * 1000) / 10;
  }

  /**
   * Selector selectedCorrectSlideResults$
   *
   * @param selectedSlideResults: FlatModels.TrainingViewerModels.TrainingResultType[]
   */
  @Selector([TrainingViewerState.selectedSlideResults$])
  public static selectedCorrectSlideResults$(selectedSlideResults: FlatModels.TrainingViewerModels.TrainingResultType[]) {
    if (Array.isArray(selectedSlideResults)) {
      return selectedSlideResults.map(r => r.correct).reduce((sum, a) => sum + a, 0);
    }
    return 0;
  }

  /**
   * Selector selectedCorrectPercentageSlideResults
   *
   * @param selectedTotalSlideResults: number
   * @param selectedCorrectSlideResults: number
   */
  @Selector([
    TrainingViewerState.selectedTotalSlideResults$,
    TrainingViewerState.selectedCorrectSlideResults$
  ])
  public static selectedCorrectPercentageSlideResults$(selectedTotalSlideResults: number,
                                                       selectedCorrectSlideResults: number) {
    return Math.round(selectedCorrectSlideResults / selectedTotalSlideResults * 1000) / 10;
  }

  /**
   * Selector selectedWrongSlideResults$
   *
   * @param selectedSlideResults: FlatModels.TrainingViewerModels.TrainingResultType[]
   */
  @Selector([TrainingViewerState.selectedSlideResults$])
  public static selectedWrongSlideResults$(selectedSlideResults: FlatModels.TrainingViewerModels.TrainingResultType[]) {
    if (Array.isArray(selectedSlideResults)) {
      return selectedSlideResults.map(r => r.wrong).reduce((sum, a) => sum + a, 0);
    }
    return 0;
  }

  /**
   * Selector selectedWrongPercentageSlideResults$
   *
   * @param selectedTotalSlideResults: number
   * @param selectedWrongSlideResults: number
   */
  @Selector([
    TrainingViewerState.selectedTotalSlideResults$,
    TrainingViewerState.selectedWrongSlideResults$
  ])
  public static selectedWrongPercentageSlideResults$(selectedTotalSlideResults: number,
                                                     selectedWrongSlideResults: number) {
    return Math.round(selectedWrongSlideResults / selectedTotalSlideResults * 1000) / 10;
  }

  /**
   * Selector currentSlideResults$
   *
   * @param typedSelectedSlideResults: { [id: string]: FlatModels.TrainingViewerModels.TrainingResult[] }
   * @param currentSelectedSlidePage: FlatModels.PageEntityModels.Training
   */
  @Selector([
    TrainingViewerState.typedSelectedSlideResults$,
    FlatStates.PageEntityState.currentSelectedSlidePage$
  ])
  public static currentSlideResults$(typedSelectedSlideResults: { [id: string]: FlatModels.TrainingViewerModels.TrainingResultType[] },
                                     currentSelectedSlidePage: FlatModels.PageEntityModels.Training) {
    return currentSelectedSlidePage && typedSelectedSlideResults && typedSelectedSlideResults[currentSelectedSlidePage.id] !== undefined ? typedSelectedSlideResults[currentSelectedSlidePage.id] : [];
  }

  /**
   * Selector currentSlideResultShowFeedback$
   *
   * @param currentSlideResults: FlatModels.TrainingViewerModels.TrainingResultType[]
   */
  @Selector([TrainingViewerState.currentSlideResults$])
  public static currentSlideResultShowFeedback$(currentSlideResults: FlatModels.TrainingViewerModels.TrainingResultType[]) {
    return Array.isArray(currentSlideResults) ? currentSlideResults.every(ctr => ctr.showFeedback) : false;
  }

  /**
   * Selector currentSlideResultShowExplanation$
   *
   * @param currentSlideResults: FlatModels.TrainingViewerModels.TrainingResultType[]
   */
  @Selector([TrainingViewerState.currentSlideResults$])
  public static currentSlideResultShowExplanation$(currentSlideResults: FlatModels.TrainingViewerModels.TrainingResultType[]) {
    return Array.isArray(currentSlideResults) ? currentSlideResults.every(ctr => ctr.showExplanation) : false;
  }

  /**
   * Lazy Selector currentSlideResultByQuestionId$
   *
   * @param currentSlideResults: FlatModels.TrainingViewerModels.TrainingResult[]
   */
  @Selector([TrainingViewerState.currentSlideResults$, FlatStates.QuestionEntityState.typedEntities$, FlatStates.PageEntityState.currentSelectedSlidePage$])
  public static currentSlideResultByQuestionId$(currentSlideResults: FlatModels.TrainingViewerModels.TrainingResultType[], 
                                                questions: {[id: string]: FlatModels.QuestionEntityModels.QuestionEntityType},
                                                currentSelectedSlidePage: FlatModels.PageEntityModels.Training) {    
    return (questionId: string) => {
      let result = Array.isArray(currentSlideResults) && currentSlideResults.find(sr => sr.questionId === questionId) || null;
      if (!result) {
        result = questions?.[questionId] && currentSelectedSlidePage?.id ? _createQuestionResult(questions[questionId], currentSelectedSlidePage.id) : null;
      }
      return result;
    };
  }

  /**
   * Selector selectedSlideResultsRequiredAnswered$
   *
   * @param typedSelectedSlideResults: { [id: string]: FlatModels.TrainingViewerModels.TrainingResultType[] }
   */
  @Selector([TrainingViewerState.typedSelectedSlideResults$])
  public static selectedSlideResultsRequiredAnswered$(typedSelectedSlideResults: { [id: string]: FlatModels.TrainingViewerModels.TrainingResultType[] }) {
    const slideResultsRequiredAnswered: { [id: string]: boolean } = {};
    Object.entries(typedSelectedSlideResults).forEach(([id, trainingResults]) => {
      if (trainingResults.length === 0) {
        slideResultsRequiredAnswered[id] = true;
      } else {
        const requiredResults = trainingResults.filter(qr => qr.required == true);
        slideResultsRequiredAnswered[id] = requiredResults.every(r => r.showFeedback);
      }
    });
    return slideResultsRequiredAnswered;
  }

  /**
   * Selector slideSelectionAvailable$
   *
   * @param slideResultsRequiredAnswered: { [id: string]: boolean }
   * @param selectedSlides: string[]
   * @param selectedSlideNodes: FlatModels.NodeEntityModels.NodeEntityType
   */
  @Selector([
    TrainingViewerState.selectedSlideResultsRequiredAnswered$,
    TrainingViewerState.selectedSlides$,
    TrainingViewerState.selectedSlideNodes$
  ])
  public static slideSelectionAvailable$(slideResultsRequiredAnswered: { [id: string]: boolean },
                                         selectedSlides: string[],
                                         selectedSlideNodes: FlatModels.NodeEntityModels.NodeEntityType[]) {
    const slideResultsAvailable: { [id: string]: boolean } = {};
    let isAvailableSeriesBlock = true;
    let isAvailableSeriesBlockIndex = -1;
    for (let i = 0; i < selectedSlides.length; i++) {
      const slideId = selectedSlides[i];
      const slideNode = selectedSlideNodes.find(sn => sn.id === slideId);
      if (!slideNode) {
        return {}
        throw new Error("no_slide_node_found");
      }
      const pageId = slideNode.page as unknown as string;
      if (isAvailableSeriesBlock) {
        const isAvailable = slideResultsRequiredAnswered[pageId] || false;
        if (!isAvailable) {
          slideResultsAvailable[pageId] = isAvailableSeriesBlockIndex === (i - 1);
          isAvailableSeriesBlock = false;
        } else {
          slideResultsAvailable[pageId] = isAvailable;
          isAvailableSeriesBlockIndex = i;
        }
      } else {
        slideResultsAvailable[pageId] = false;
      }
    }
    return slideResultsAvailable;
  }

  /**
   * Selector isTrainingSetupCorrectly$
   *
   * @param selectedSlides: string[]
   * @param typedSelectedSlideResults: { [id: string]: FlatModels.TrainingViewerModels.TrainingResultType[] }
   */
  @Selector([
    TrainingViewerState.selectedSlides$,
    TrainingViewerState.typedSelectedSlideResults$,
  ])
  public static isTrainingSetupCorrectly$(selectedSlides: string[],
                                          typedSelectedSlideResults: { [id: string]: FlatModels.TrainingViewerModels.TrainingResultType[] }) {
    return selectedSlides.length > 0 && selectedSlides.length === Object.entries(typedSelectedSlideResults).length;
  }

  /**
   * Selector trainingSetupIncorrectlyUrl$
   *
   * @param blockPage: FlatModels.PageEntityModels.PageEntityType
   * @param subjectUrl: string | null
   * @param chapterUrl: string | null
   */
  @Selector([
    FlatStates.PageEntityState.currentSelectedBlockPage$,
    FlatStates.NavigationControlState.subjectUrl$,
    FlatStates.NavigationControlState.chapterUrl$,
  ])
  public static trainingSetupIncorrectlyUrl$(blockPage: FlatModels.PageEntityModels.PageEntityType,
                                             subjectUrl: string | null,
                                             chapterUrl: string | null) {
    if (blockPage.type === FlatModels.PageEntityModels.PageType.CASE) {
      return chapterUrl;
    } else {
      return subjectUrl + '/quiz';
    }
  }

  /**
   * Selector slideUrlByNode$
   *
   * @param treeElements: FlatModels.TreeControlModels.TreeElement[]
   */
  @Selector([FlatStates.TreeControlState.entities$])
  public static slideUrlByNode$(treeElements: FlatModels.TreeControlModels.TreeElement[]) {
    return (slideNode: FlatModels.NodeEntityModels.SlideNode) => {
      const treeElement = treeElements.find(te => te.id === slideNode.parentId);
      if (treeElement) {
       return ['/quiz/' + treeElement.path + '/' + slideNode.route];
      } else {
        throw Error("current_parent_tree_not_defined");
      }
    }
  }

  //</editor-fold>

  /**
   * Constructor
   *
   * @param store: Store
   * @param messageControlService: MessageControlService
   * @param matomoControlService: MatomoControlService
   */
  public constructor(public store: Store,
                     public messageControlService: MessageControlService,
                     public matomoControlService: MatomoControlService) {
  }

  //<editor-fold desc="Actions">

  /**
   * Get Training Nodes By Current SubjectId
   */
  @Action(FlatActions.TrainingViewerActions.GetTrainingNodesByCurrentSubject)
  public getTrainingNodesByCurrentSubject() {
    const currentAvailableSlides = this.store.selectSnapshot(TrainingViewerState.currentAvailableSlides$);
    if (currentAvailableSlides === null) {
      const subjectNode = this.store.selectSnapshot(FlatStates.NodeEntityState.currentSelectedSubjectNode$);
      if (subjectNode) {
        return this.messageControlService.sendMessage(
          this.store.selectSnapshot(FlatStates.AuthControlState.token$),
          new FlatActions.TrainingViewerActions.GetTrainingNodesBySubjectIdRequest(subjectNode.id)
        );
      }
    }
    return null;
  }

  /**
   * Get Training Nodes By Selected Slides
   */
  @Action(FlatActions.TrainingViewerActions.GetTrainingNodesBySelectedSlides)
  public getTrainingNodesBySelectedSlides() {
    const selectedSlides = this.store.selectSnapshot(TrainingViewerState.selectedSlides$);
    if (selectedSlides.length > 0) {
      return this.messageControlService.sendMessage(
        this.store.selectSnapshot(FlatStates.AuthControlState.token$),
        new FlatActions.TrainingViewerActions.GetTrainingNodesByIdsRequest(selectedSlides)
      );
    }
    return null;
  }

  /**
   * Get Slide Entity Success
   *
   * @param getState: StateContext<TrainingViewerStateModel>
   * @param patchState: StateContext<TrainingViewerStateModel>
   * @param dispatch: StateContext<TrainingViewerStateModel>
   * @param entityQueryResult: TrainingViewerActions.GetSlideEntitySuccess
   */
  @Action(FlatActions.TrainingViewerActions.GetSlideEntitySuccess)
  public getSlideEntitySuccess({getState, patchState, dispatch}: StateContext<TrainingViewerStateModel>,
                               {entityQueryResult}: FlatActions.TrainingViewerActions.GetSlideEntitySuccess) {
    if (entityQueryResult.subjectId) {
      const availableSlides = Object.assign({}, getState().availableSlides);
      availableSlides[entityQueryResult.subjectId] = [...availableSlides[entityQueryResult.subjectId] || [], {id: entityQueryResult.entity.id, parentId: entityQueryResult.entity.parentId as string}];
      patchState({
        availableSlides
      });
      dispatch(new FlatActions.NodeEntityActions.GetEntitySuccess(entityQueryResult));
    } else {
      throw new Error("no_subjectId_available");
    }
  }

  /**
   * Get Slide Entities Success
   *
   * @param getState: StateContext<TrainingViewerStateModel>
   * @param patchState: StateContext<TrainingViewerStateModel>
   * @param dispatch: StateContext<TrainingViewerStateModel>
   * @param entityQueryResults: TrainingViewerActions.GetSlideEntitiesSuccess
   */
  @Action(FlatActions.TrainingViewerActions.GetSlideEntitiesSuccess)
  public getSlideEntitiesSuccess({getState, patchState, dispatch}: StateContext<TrainingViewerStateModel>,
                                 {entityQueryResults}: FlatActions.TrainingViewerActions.GetSlideEntitiesSuccess) {
    let noSubjectId = false;
    const availableSlides = Object.assign({}, getState().availableSlides);
    for (const entityQueryResult of entityQueryResults) {
      if (entityQueryResult.subjectId) {
        availableSlides[entityQueryResult.subjectId] = [...availableSlides[entityQueryResult.subjectId] || [], {id: entityQueryResult.entity.id, parentId: entityQueryResult.entity.parentId as string}];
      } else {
        noSubjectId = true;
      }
    }
    if (noSubjectId) {
      throw new Error("no_subjectId_available");
    }
    patchState({
      availableSlides
    });
    dispatch(new FlatActions.NodeEntityActions.GetEntitiesSuccess(entityQueryResults));
  }

  /**
   * Get Block Entity Success
   *
   * @param getState: StateContext<TrainingViewerStateModel>
   * @param patchState: StateContext<TrainingViewerStateModel>
   * @param dispatch: StateContext<TrainingViewerStateModel>
   * @param entityQueryResult: TrainingViewerActions.GetBlockEntitySuccess
   */
  @Action(FlatActions.TrainingViewerActions.GetBlockEntitySuccess)
  public getBlockEntitySuccess({getState, patchState, dispatch}: StateContext<TrainingViewerStateModel>,
                               {entityQueryResult}: FlatActions.TrainingViewerActions.GetBlockEntitySuccess) {
    if (entityQueryResult.subjectId) {
      const availableBlocks = Object.assign({}, getState().availableBlocks);
      availableBlocks[entityQueryResult.subjectId] = [...availableBlocks[entityQueryResult.subjectId] || [], {id: entityQueryResult.entity.id, parentId: entityQueryResult.entity.parentId as string}];
      patchState({
        availableBlocks
      });
      dispatch(new FlatActions.NodeEntityActions.GetEntitySuccess(entityQueryResult));
    } else {
      throw new Error("no_subjectId_available");
    }
  }

  /**
   * Get Block Entities Success
   *
   * @param getState: StateContext<TrainingViewerStateModel>
   * @param patchState: StateContext<TrainingViewerStateModel>
   * @param dispatch: StateContext<TrainingViewerStateModel>
   * @param entityQueryResults: TrainingViewerActions.GetBlockEntitiesSuccess
   */
  @Action(FlatActions.TrainingViewerActions.GetBlockEntitiesSuccess)
  public getBlockEntitiesSuccess({getState, patchState, dispatch}: StateContext<TrainingViewerStateModel>,
                                 {entityQueryResults}: FlatActions.TrainingViewerActions.GetBlockEntitiesSuccess) {
    let noSubjectId = false;
    const availableBlocks = Object.assign({}, getState().availableBlocks);
    for (const entityQueryResult of entityQueryResults) {
      if (entityQueryResult.subjectId) {
        availableBlocks[entityQueryResult.subjectId] = [...availableBlocks[entityQueryResult.subjectId] || [], {id: entityQueryResult.entity.id, parentId: entityQueryResult.entity.parentId as string}];
      } else {
        noSubjectId = true;
      }
    }
    if (noSubjectId) {
      throw new Error("no_subjectId_available");
    }
    patchState({
      availableBlocks
    });
    dispatch(new FlatActions.NodeEntityActions.GetEntitiesSuccess(entityQueryResults));
  }

  /**
   * Toggle Training Selection
   *
   * @param getState: StateContext<TrainingViewerStateModel>
   * @param patchState: StateContext<TrainingViewerStateModel>
   * @param slides: TrainingViewerActions.ToggleTrainingSelection
   * @param override: boolean
   */
  @Action(FlatActions.TrainingViewerActions.ToggleTrainingSelection)
  public toggleTrainingSelection({getState, patchState}: StateContext<TrainingViewerStateModel>,
                                 {slides, override}: FlatActions.TrainingViewerActions.ToggleTrainingSelection) {
    // TODO removeItem
    const selectedSlides = getState().selectedSlides;
    for (const slide of slides) {
      const index = selectedSlides.indexOf(slide.id);
      if (index > -1) {
        if (!override) {
          selectedSlides.splice(index, 1);
        }
      } else {
        if (override) {
          selectedSlides.push(slide.id);
        }
      }
    }
    patchState({
      selectedSlides: selectedSlides
    });
  }

  /**
   * Reset
   *
   * @param patchState: StateContext<TrainingViewerStateModel>
   */
  @Action(FlatActions.TrainingViewerActions.Reset)
  public reset({patchState}: StateContext<TrainingViewerStateModel>) {
    patchState({
      selectedStartUrl: null,
      selectedSlides: [],
      selectedSlideResults: {}
    });
  }

  /**
   * Start Training
   *
   * @param getState: StateContext<TrainingViewerStateModel>
   * @param patchState: StateContext<TrainingViewerStateModel>
   * @param dispatch: StateContext<TrainingViewerStateModel>
   */
  @Action(FlatActions.TrainingViewerActions.SetupTraining)
  public setupTraining({getState, patchState, dispatch}: StateContext<TrainingViewerStateModel>) {
    // TODO updateItem
    // Shuffle Selected Slides
    const selectedSlides = getState().selectedSlides;
    const shuffledSelectedSlides = shuffle(selectedSlides);

    // Get Questions
    const questions = this.store.selectSnapshot(FlatStates.QuestionEntityState.typedEntities$);

    // Setup Training Results
    const selectedTrainingPages = this.store.selectSnapshot(TrainingViewerState.selectedSlidePages$);
    const selectedTrainingResults: { [id: string]: FlatModels.TrainingViewerModels.TrainingResultType[] } = {};
    selectedTrainingPages.forEach(tp => {
      selectedTrainingResults[tp.id] = [];
      (tp.questions || []).forEach((q) => {
        let total = 0;
        let answer = undefined;
        let solutions: string[] | undefined = [];
        const question = questions[q as unknown as string];
        if (question) {
          switch (question.type) {
            case FlatModels.QuestionEntityModels.QuestionType.MAPPING:
            case FlatModels.QuestionEntityModels.QuestionType.KPRIME:
            case FlatModels.QuestionEntityModels.QuestionType.TRUE_FALSE:
              // Cast
              solutions = (question as FlatModels.QuestionEntityModels.MappingQuestion | FlatModels.QuestionEntityModels.KprimeQuestion
                | FlatModels.QuestionEntityModels.TrueFalseQuestion).solutions;

              // Setup
              if (solutions) {
                total = solutions.length || 0;
                answer = solutions.map(() => '');
              }
              break;
            case FlatModels.QuestionEntityModels.QuestionType.INDICATION:
            case FlatModels.QuestionEntityModels.QuestionType.LONG_LIST:
            case FlatModels.QuestionEntityModels.QuestionType.SINGLE_CHOICE:
            case FlatModels.QuestionEntityModels.QuestionType.FREE_TEXT:
              total = 1;
              answer = null;
              break;
            default:
              throw Error("not_implemented"); // MULTIPLE_CHOICE = 'MultipleChoiceQuestion',
          }
          selectedTrainingResults[tp.id].push({
            trainingId: tp.id,
            questionId: question.id,
            required: question.required || false,
            order: question.order,
            answer,
            showFeedback: false,
            showExplanation: false,
            total,
            empty: total,
            correct: 0,
            wrong: 0,
          });
        }
      });
    });

    const routerState = this.store.selectSnapshot(RouterState);

    // Setup Training
    patchState({
      selectedStartUrl: routerState.state.url,
      selectedSlides: shuffledSelectedSlides,
      selectedSlideResults: selectedTrainingResults
    });

    // Start Training
    dispatch(new FlatActions.TrainingViewerActions.Start());
  }

  /**
   * Start Case
   *
   * @param patchState: StateContext<TrainingViewerStateModel>
   * @param dispatch: StateContext<TrainingViewerStateModel>
   */
  @Action(FlatActions.TrainingViewerActions.SetupCase)
  public setupCase({patchState, dispatch}: StateContext<TrainingViewerStateModel>) {
    // Get Selected Slides
    const currentBlockNode = this.store.selectSnapshot(FlatStates.NodeEntityState.currentSelectedBlockNode$);
    const selectedTrainings = currentBlockNode ? currentBlockNode.children as unknown as string[] || [] : [];

    // Get Questions
    const questions = this.store.selectSnapshot(FlatStates.QuestionEntityState.typedEntities$);

    // Set Selected Slides
    patchState({
      selectedSlides: selectedTrainings,
    });

    // Setup Case Results
    const currentSelectedCasePages = this.store.selectSnapshot(TrainingViewerState.selectedSlidePages$);
    const selectedTrainingResults: { [id: string]: FlatModels.TrainingViewerModels.TrainingResultType[] } = {};
    currentSelectedCasePages.forEach(tp => {
      selectedTrainingResults[tp.id] = [];
       (tp.questions || []).forEach((q) => {
        const question = questions[q as unknown as string];
        if (question) {
          const result = _createQuestionResult(question, tp.id);
          selectedTrainingResults[tp.id].push(result);
        }
      });
    });

    const routerState = this.store.selectSnapshot(RouterState);

    // Setup Case
    patchState({
      selectedStartUrl: routerState.state.url,
      selectedSlideResults: selectedTrainingResults
    });

    // Start Case
    dispatch(new FlatActions.TrainingViewerActions.Start());
  }

  /**
   * Start
   *
   * @param getState: StateContext<TrainingViewerStateModel>
   * @param dispatch: StateContext<TrainingViewerStateModel>
   */
  @Action(FlatActions.TrainingViewerActions.Start)
  public start({getState, dispatch}: StateContext<TrainingViewerStateModel>) {
    const selectedSlides = getState().selectedSlides;
    const selectedSlideNodes = this.store.selectSnapshot(TrainingViewerState.selectedSlideNodes$);
    if (selectedSlideNodes.length > 0) {
      const selectedSlideNode = selectedSlideNodes.find(sn => sn.id === selectedSlides[0]);
      if (selectedSlideNode) {
        const treeElements = this.store.selectSnapshot(FlatStates.TreeControlState.entities$);
        const treeElement = treeElements.find(te => te.id === selectedSlideNode.parentId);
        if (treeElement) {
          // TODO only track quiz? or also case
          // Matomo Track Quiz / Case Start
          this.matomoControlService.trackQuizStart(getState().selectedSlides);

          // Navigate to first slide
          dispatch(new Navigate(['quiz/' + treeElement.path + '/' + selectedSlideNode.route], {}, { replaceUrl: true }));
        } else {
          throw Error("current_parent_tree_not_defined");
        }
      } else {
        throw Error("current_slide_node_not_defined");
      }
    } else {
      throw Error("no_slides_selected");
    }
  }

  /**
   * Update Slide Result
   *
   * @param setState: StateContext<TrainingViewerStateModel>
   * @param slideResult: TrainingViewerActions.UpdateSlideResult
   */
  @Action(FlatActions.TrainingViewerActions.UpdateSlideResult)
  public updateSlideResult({getState, patchState}: StateContext<TrainingViewerStateModel>,
                           {slideResult}: FlatActions.TrainingViewerActions.UpdateSlideResult) {

    const selectedSlideResults = getState().selectedSlideResults;
    const trainingResult = selectedSlideResults[slideResult.trainingId] || [slideResult];
    const index = trainingResult.findIndex(r => r.questionId === slideResult.questionId);
    const updatedTrainingResult = [...trainingResult];
    if (index !== -1) {
      updatedTrainingResult[index] = slideResult;
    } else {
      updatedTrainingResult.push(slideResult);
    }

    patchState({
      selectedSlideResults: {
        ...selectedSlideResults,
        [slideResult.trainingId]: updatedTrainingResult
      }
    });
  }

  @Action(FlatActions.TrainingViewerActions.SetShowFeedback)
  /**
   * Set Show Feedback
   *
   * @param setState: StateContext<TrainingViewerStateModel>
   * @param slideResult: TrainingViewerActions.SetShowFeedback
   * @param question: TrainingViewerActions.SetShowFeedback
   */
  public setShowFeedback({dispatch}: StateContext<TrainingViewerStateModel>,
                         {slideResult, question}: FlatActions.TrainingViewerActions.SetShowFeedback) {
    slideResult.showFeedback = true;

    // Results
    let solutions: string[] | undefined = [];
    let solution: number | undefined = undefined;
    switch (question.type) {
      case FlatModels.QuestionEntityModels.QuestionType.MAPPING:
      case FlatModels.QuestionEntityModels.QuestionType.KPRIME:
      case FlatModels.QuestionEntityModels.QuestionType.TRUE_FALSE:
        // Cast
        solutions = (question as FlatModels.QuestionEntityModels.MappingQuestion
          | FlatModels.QuestionEntityModels.KprimeQuestion | FlatModels.QuestionEntityModels.TrueFalseQuestion).solutions;

        // Setup Results
        if (solutions) {
          slideResult.empty = solutions.filter((s, i) => Array.isArray(slideResult.answer) && slideResult.answer[i] === '').length;
          slideResult.correct = solutions.filter((s, i) => Array.isArray(slideResult.answer) && slideResult.answer[i] === s).length;
          slideResult.wrong = (slideResult.total || 0) - slideResult.empty - slideResult.correct;
        }
        break;
      case FlatModels.QuestionEntityModels.QuestionType.INDICATION:
      case FlatModels.QuestionEntityModels.QuestionType.LONG_LIST:
      case FlatModels.QuestionEntityModels.QuestionType.SINGLE_CHOICE:
        // Cast
        solution = (question as FlatModels.QuestionEntityModels.IndicationQuestion
          | FlatModels.QuestionEntityModels.LongListQuestion | FlatModels.QuestionEntityModels.SingleChoiceQuestion).solution;

        // Setup Results
        slideResult.empty = (slideResult.answer === null) ? 1 : 0;
        slideResult.correct = (slideResult.answer === solution) ? 1 : 0;
        slideResult.wrong = (slideResult.empty !== 1 && slideResult.correct !== 1) ? 1 : 0;
        break;
      case FlatModels.QuestionEntityModels.QuestionType.FREE_TEXT:
        break;
      default:
        throw Error("not_implemented"); // MULTIPLE_CHOICE = 'MultipleChoiceQuestion',
    }
    dispatch(new FlatActions.TrainingViewerActions.UpdateSlideResult(slideResult));
  }

  /**
   * Toggle Show Explanation
   *
   * @param setState: StateContext<TrainingViewerStateModel>
   * @param slideResult: TrainingViewerActions.ToggleShowExplanation
   */
  @Action(FlatActions.TrainingViewerActions.ToggleShowExplanation)
  public toggleShowExplanation({dispatch}: StateContext<TrainingViewerStateModel>,
                               {slideResult}: FlatActions.TrainingViewerActions.ToggleShowExplanation) {
    slideResult.showExplanation = !slideResult.showExplanation;
    dispatch(new FlatActions.TrainingViewerActions.UpdateSlideResult(slideResult));
  }

  /**
   * Go To Start
   *
   * @param getState: StateContext<TrainingViewerStateModel>
   * @param dispatch: StateContext<TrainingViewerStateModel>
   */
  @Action(FlatActions.TrainingViewerActions.GoToStart)
  public goToStart({getState, dispatch}: StateContext<TrainingViewerStateModel>) {
    const startUrl = getState().selectedStartUrl;
    if (startUrl) {
      dispatch(new Navigate([startUrl]));
    }
  }

  /**
   * Go To Slide By Node
   *
   * @param dispatch: StateContext<TrainingViewerStateModel>
   * @param slideNode: FlatActions.TrainingViewerActions.GoToSlideByNode
   */
  @Action(FlatActions.TrainingViewerActions.GoToSlideByNode)
  public goToSlideByNode({dispatch}: StateContext<TrainingViewerStateModel>,
                         {slideNode}: FlatActions.TrainingViewerActions.GoToSlideByNode) {
    if (slideNode) {
      const treeElements = this.store.selectSnapshot(FlatStates.TreeControlState.entities$);
      const treeElement = treeElements.find(te => te.id === slideNode.parentId);
      if (treeElement) {
        // Navigate to first slide
        dispatch(new Navigate(['quiz/' + treeElement.path + '/' + slideNode.route]));
      } else {
        throw Error("current_parent_tree_not_defined");
      }
    } else {
      throw Error("current_slide_node_not_defined");
    }
  }

  /**
   * Track Quiz Answer
   *
   * @param state: StateContext<TrainingViewerStateModel>
   * @param slideId: string
   * @param question: FlatModels.QuestionEntityModels.QuestionEntityType
   * @param isCorrect: boolean
   */
  @Action(FlatActions.TrainingViewerActions.TrackQuizAnswer)
  public trackQuizAnswer(state: StateContext<TrainingViewerStateModel>,
                         {slideId, question, isCorrect}: FlatActions.TrainingViewerActions.TrackQuizAnswer) {
    // Matomo
    this.matomoControlService.trackQuizAnswer(slideId, question, isCorrect);
  }

  //</editor-fold>
}


function _createQuestionResult(question: FlatModels.QuestionEntityModels.QuestionEntityType, slideId: string) {
  let total = 0;
  let answer = undefined;
  let solutions: string[] | undefined = [];
  switch (question.type) {
    case FlatModels.QuestionEntityModels.QuestionType.MAPPING:
    case FlatModels.QuestionEntityModels.QuestionType.KPRIME:
    case FlatModels.QuestionEntityModels.QuestionType.TRUE_FALSE:
      // Cast
      solutions = (question as FlatModels.QuestionEntityModels.MappingQuestion | FlatModels.QuestionEntityModels.KprimeQuestion
        | FlatModels.QuestionEntityModels.TrueFalseQuestion).solutions;

      // Setup
      if (solutions) {
        total = solutions.length || 0;
        answer = solutions.map(() => '');
      }
      break;
    case FlatModels.QuestionEntityModels.QuestionType.INDICATION:
    case FlatModels.QuestionEntityModels.QuestionType.LONG_LIST:
    case FlatModels.QuestionEntityModels.QuestionType.SINGLE_CHOICE:
    case FlatModels.QuestionEntityModels.QuestionType.FREE_TEXT:
      total = 1;
      answer = null;
      break;
    default:
      throw Error("not_implemented"); // MULTIPLE_CHOICE = 'MultipleChoiceQuestion',
  }
  return {
    trainingId: slideId,
    questionId: question.id,
    required: question.required || false,
    order: question.order,
    answer,
    showFeedback: false,
    showExplanation: false,
    total,
    empty: total,
    correct: 0,
    wrong: 0,
  };
}