import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import * as FlatModels from '@medsurf/flat-models';
import { MediaControlService } from '@medsurf/flat-services';

@Component({
  selector: 'medsurf-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent {
  /**
   * Members
   */
  public errorState = false;
  public progress = 0;
  public isSeeking = false;
  public isPlaying = false;
  public loop = false;
  
  public playbackSpeed = [0.25, 0.5, 1, 1.25, 1.5, 1.75, 2];
  public speed = 1;

  /**
   * Inputs
   */
  @Input() public media: FlatModels.MediaEntityModels.MediaEntityType;

  /**
   * View Children
   */
  @ViewChild('progressWrap', {static: false}) public progressElementRef: ElementRef;
  @ViewChild('videoElement', {static: false}) public videoElementRef: ElementRef;

  public adjustPlaybackSpeed(playbackSpeed: number){
    this.speed = playbackSpeed;
    this.videoElementRef.nativeElement.playbackRate = this.speed;
  }

  public toggleEndlessLoop() {
    this.loop = !this.loop;
    this.videoElementRef.nativeElement.loop = this.loop;
  }

  /**
   * Constructor
   *
   * @param mediaControlService: MediaControlService
   */
  constructor(public mediaControlService: MediaControlService) {
  }

  /**
   * Local Toggle
   */
  public localToggle() {
    if (!this.videoElementRef.nativeElement && !this.videoElementRef.nativeElement.paused) {
      return;
    }
    if (this.videoElementRef.nativeElement.paused === true) {
      this.videoElementRef.nativeElement.play();
      this.isPlaying = true;
    } else {
      this.videoElementRef.nativeElement.pause();
      this.isPlaying = false;
    }
  }

  /**
   * Local Seek Bar Mousedown
   *
   * @param event: MouseEvent
   */
  public localSeekBarMousedown(event: MouseEvent) {
    this.isSeeking = true;
    this.seek(this.progressElementRef.nativeElement.offsetWidth / event.offsetX);
  }

  /**
   * Seek
   *
   * @param value: number
   * @private
   */
  private seek(value: number): void {
    this.videoElementRef.nativeElement.currentTime = this.videoElementRef.nativeElement.duration / value;
  }

  /**
   * Update Process
   */
  public updateProgress(): void {
    this.progress = this.videoElementRef.nativeElement ?
      (1 / this.videoElementRef.nativeElement.duration) * this.videoElementRef.nativeElement.currentTime : 0;
  }

  /**
   * Handle Mouse Move
   *
   * @param event: MouseEvent
   */
  @HostListener('window:mousemove', ['$event'])
  public handleMouseMove(event: MouseEvent) {
    if (this.isSeeking) {
      this.seek(this.progressElementRef.nativeElement.offsetWidth / event.offsetX);
    }
  }

  /**
   * Handle Mouse Up
   *
   * @param event: MouseEvent
   */
  @HostListener('window:mouseup', ['$event'])
  public handleMouseUp(event: MouseEvent) {
    if (this.isSeeking) {
      this.isSeeking = false;
      this.seek(this.progressElementRef.nativeElement.offsetWidth / event.offsetX);
    }
  }

  /**
   * On Right Click
   *
   * @param event: Event
   */
  @HostListener('contextmenu', ['$event'])
  public onRightClick(event: Event) {
    event.preventDefault();
  }

  /**
   * Handle Space Key
   */
  @HostListener('window:keydown.space')
  public handleSpaceKey() {
    this.localToggle();
  }
}
